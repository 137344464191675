@media (max-width: 650px ) {
}@media (max-width: 1024px ) {
}@media (max-width: 1024px ) {
}@media (max-width: 1024px ) {
}.LuBiblios {
  position: relative;
}
.LuBiblios__price {
  display: block;
  -webkit-transform: translateX(-0.07em);
          transform: translateX(-0.07em);
}
.LuBiblios__priceInfos {
  padding-top: 0.5em;
  padding-bottom: 2em;
}
.LuBiblios__addToCartBtn {
  -webkit-transition: -webkit-box-shadow 0.1s ease;
  transition: -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease;
}
.LuBiblios__addToCartBtn:hover {
  -webkit-box-shadow: 0rem 0.2rem 0.5rem 0.25em rgba(0, 0, 0, 0.25);
          box-shadow: 0rem 0.2rem 0.5rem 0.25em rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.LuBiblios__addToCartMessage {
  position: relative;
  border: 1px solid currentColor;
  border-radius: 2px;
  padding: 0.5em;
  padding-right: 1.5em;
  padding-bottom: 0.75em;
  margin-top: 0.5em;
}
.LuBiblios__addToCartMessageCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25em;
  padding-right: 0.5em;
  line-height: 1em;
  border-bottom: none !important;
}
.LuBiblios__addToCartMessage {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}
.LuBiblios__addToCartMessage--showNoteSuccessMessage {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  opacity: 1;
  pointer-events: auto;
}
.LuBiblios .BaseButton__inner,
.LuBiblios .BaseButton__inner::after {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.LuBiblios .BaseButton__inner {
  position: relative;
  overflow: visible;
}
.LuBiblios .BaseButton__inner::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  white-space: pre;
}
.LuBiblios .BaseButton--de .BaseButton__inner::after {
  content: "\2714   Hinzugef\FCgt";
}
.LuBiblios .BaseButton--en .BaseButton__inner::after {
  content: "\2714   Added";
}
.LuBiblios .BaseButton--showBtnSuccessMessage {
  pointer-events: none;
}
.LuBiblios .BaseButton--showBtnSuccessMessage .BaseButton__inner {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.LuBiblios .BaseButton--showBtnSuccessMessage .BaseButton__inner::after {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.LuBiblios a {
  color: inherit;
  border-bottom: 1px dotted;
}
.LuBiblios a:hover {
  border-bottom: 1px solid;
}

@media (max-width: 650px ) {
}@media (max-width: 1024px ) {
}@media (max-width: 1024px ) {
}@media (max-width: 1024px ) {
}


/*# sourceMappingURL=ProductView.43f4a9a8.css.map*/