





























































































































































@import "../less/vars.less";
@import "../less/mixins.less";
@import (reference) "../less/atoms.less";



.LuBiblios {
	position: relative;

	&__price {
		display: block;
		transform: translateX(-0.07em);
	}
	&__priceInfos {
		padding-top: 0.5em;
		padding-bottom: 2em;
	}
	&__addToCartBtn {
		transition: box-shadow 0.1s ease;
	}
	&__addToCartBtn:hover {
		.shadow--cartButton;
	}
	&__addToCartMessage {
		position: relative;
		border: 1px solid currentColor;
		border-radius: 2px;
		padding: 0.5em; padding-right: 1.5em;
		padding-bottom: 0.75em;
		margin-top: 0.5em;

		&CloseBtn {
			//background-color: fade( red, 50 );

			position: absolute;
			top: 0; right: 0;
			padding: 0.25em;
			padding-right: 0.5em;
			line-height: 1em;
			border-bottom: none !important;
		}
	}
	&__addToCartMessage {
		transition: all 0.2s ease;
		transform: translateY(-100%);
		opacity: 0;
		pointer-events: none;
	}
	&__addToCartMessage--showNoteSuccessMessage {
		transform: translateY(0%);
		opacity: 1;
		pointer-events: auto;
	}

	// Btn Animation nachdem ein Produkt in den
	// Warenkorb gelegt wurde
	.BaseButton {
		&__inner,
		&__inner::after {
			transition: all 0.2s ease;
		}
		&__inner {
			position: relative;
			overflow: visible;
		}
		&__inner::after {
			//background-color: fade( red, 75 );

			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: translateY(100%);
			transform: translateY(-100%);
			white-space: pre;
		}

		&--de .BaseButton__inner::after {
			content: "✔ Hinzugefügt";
		}
		&--en .BaseButton__inner::after {
			content: "✔ Added";
		}
	}
	.BaseButton--showBtnSuccessMessage {
		pointer-events: none;

		.BaseButton__inner {
			transform: translateY(100%);
		}
		.BaseButton__inner::after {
			transform: translateY(-100%);
			//background-color: fade( black, 10 );
		}
	}

	a {
		color: inherit;
		border-bottom: 1px dotted;
	}
	a:hover {
		border-bottom: 1px solid;
	}
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
