@import "./vars.less";
@import "./mixins.less";
@import "./fonts.less";

.reset {} // reset muss ganz oben stehen, damit es leicht wieder überschrieben werden kann
.reset--button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	outline: none;
	text-align: inherit;
	cursor: pointer;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
	
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
.reset--list {
	list-style: none;
}	

.background {}
.background--sidebar {
	background-color: white;
}
.background--productTeaser {
	background-color: @swatches[shopBackground];
}	
.background--black {
	background-color: lighten( black, 0 );
}	
.background--cartButtonHover {
	background-color: lighten( black, 12 );
}	

.button	{
	.reset--button;
	.font--sizeMedium;
	.font--bold;
	.border--all;
	.border--medium;
	
	transition: all 0.05s ease;
	padding: 0.5rem 0.65rem;
	max-width: 100%;	
	overflow: hidden;
	
	line-height: 1em;
	white-space: nowrap;
	text-overflow: ellipsis;
	z-index: 2;
	
	&:hover {
		.shadow--cartButton;
		.background--cartButtonHover;
	}
	&:active {
		.shadow--none;
		transform: translateY(0.1rem);
	}
}

.border {}
.border--left {
	border-left: 1px solid;
}
.border--right {
	border-right: 1px solid;
}
.border--bottom {
	border-bottom: 1px solid;
}
.border--top {
	border-top: 1px solid;
}
.border--all {
	border: 1px solid;
}
.border--medium {
	border-width: 2px;
}
.border--strong {
	border-width: @border[strongWidth];
}

.block {
	display: block;		
}

.color {}
.color--white {
	color: @swatches[textWhite]; 
}
.color--award {
	color: @swatches[awardColor]; 
}

.font {}
.font--sans {
	font-family: 'Circular Pro', sans-serif;
	font-family: 'Work Sans', sans-serif;
}
.font--mono {
	font-family: 'Cutive Mono', monospace;
	font-size: 1.05em;
}
.font--autoSmooth {
	// der monospace font ist ziemlich mager, 
	// für den habe ich deswegen das font-smoothing 
	// lieber wieder auf auto gesetzt damit er
	// etwas fetter gerendert wird
	-webkit-font-smoothing: auto;	
	-moz-osx-font-smoothing: auto;
}
.font--italic {
	font-style: italic;
}
.font--uppercase {
	text-transform: uppercase;
	//letter-spacing: 0.08em;
}
.font--bold {
	font-weight: 600;
}
.font--bold .font--mono,
.font--bold.font--mono { // handle monospace bold, this font has no bold schnitt, so we use outlines :)
	.font--autoSmooth;
	
	font-weight: 400;
	-webkit-text-stroke: 0.06em currentColor; // 2019-07-12 this works on chrome AND firefox, even with the -webkit prefix!
	text-stroke: 0.06em currentColor; // for future browser feature
	//color: red;
}

.font--sizeHuge {			 // 38	productDetail: price + contentModule--text 
	//color: red;
	
	font-size: 38px;
	line-height: 1.1em; 
	letter-spacing: -0.04em;
	
	@media @mediaQuery__sm {
		font-size: 26px;
		letter-spacing: -0.02em;
	}
	@media @mediaQuery__xs {}
}
.font--sizeHugePrice {		 // 40
	font-size: 40px;
	line-height: 1.1em; 
	letter-spacing: -0.02em;
	
	@media @mediaQuery__sm {
		//font-size: 32px;
	}
}
.font--sizeBig {			 // 30	
	//color: cyan !important;
	font-size: 30px;
	line-height: 1.2em;
	
	@media @mediaQuery__md {}		
	@media @mediaQuery__sm {
		font-size: 22px;
	}
	@media @mediaQuery__xs {}
}
.font--sizeMedium {			 // 18	nav items + buttons only
	//color: blue !important;
	font-size: 18px;
	line-height: 1.3em;
	
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {
		font-size: 16px;
	}
	@media @mediaQuery__xs {}
}
.font--sizeDefault {		 // 15
	//color: green !important;
	font-size: 15px;
	line-height: 1.3em;
	
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {
		font-size: 13px;
	}
	@media @mediaQuery__xs {}
}	
.font--sizeSmall {	    	 // 13
	//color: red !important;
	font-size: 13px;
	line-height: 1.3em;
	
	@media @mediaQuery__md {
		//font-size: 11px;
		font-size: 12px; // 11px war zu klein
	}
}
.font--sizeMini {	    	 // 10
	//color: orange !important;
	font-size: 10px;
	line-height: 1.3em;
	
	@media @mediaQuery__md {
		font-size: 9px;
	}	
}
.font--sizeMobileMenu {		 // 18
	.font--sizeMedium;
	
	@media @mediaQuery__md {
		font-size: 18px;
	}
}
.font--sizeMobileMenuSmall { // 15
	.font--sizeSmall;
	
	@media @mediaQuery__md {
		font-size: 15px;
		line-height: 25px;
	}
}	

.flex {  
	display: flex;
}
.flex--row {
	flex-direction: row;
}
.flex--column {
	flex-direction: column;
}
.flex--grow {
	flex-grow: 1;
}
.flex--noGrow {
	flex-grow: 1;
}
.flex--shrink {
	flex-shrink: 1;
}
.flex--left {
	justify-content: flex-start;
}
.flex--center {
	justify-content: center;
}
.flex--right {
	justify-content: flex-end;
}
.flex--top {
	align-items: flex-start;
}
.flex--middle {
	align-items: center;
}
.flex--bottom {
	align-items: flex-end;
}

.grid {
	display: grid;
	grid-column-gap: @colGap;
	
	& > * {
		min-height: 0;
		min-width: 0;
	}
	
	@media @mediaQuery__md {
		grid-column-gap: @colGap*0.5;
	}
}
.grid--setAreas {
	& > *:nth-child(1){
		grid-area: a;
	}
	& > *:nth-child(2){
		grid-area: b;
	}
	& > *:nth-child(3){
		grid-area: c;
	}
	& > *:nth-child(4){
		grid-area: d;
	}
	& > *:nth-child(5){
		grid-area: e;
	}
	& > *:nth-child(6){
		grid-area: f;
	}
	& > *:nth-child(7){
		grid-area: g;
	}
	& > *:nth-child(8){
		grid-area: h;
	}
	& > *:nth-child(9){
		grid-area: i;
	}
	& > *:nth-child(10){
		grid-area: j;
	}	
}
.grid--cols-8 {
	grid-template-columns: repeat(8, 1fr);
}
.grid--cols-12 {
	grid-template-columns: repeat(12, 1fr);
}

.grid--pageTemplateForHome {
	.grid--setAreas;
	
	//color: red;
	
	grid-column-gap: @colGap;
	grid-template-columns: repeat(4, 1fr);
	grid-template-areas: 
		"a a a a"
		"b b b c"
		"d d d d";
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas: 
		"a"
		"b"
		"c"
		"d";	
	}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
}
.grid--pageTemplateForEvents {
	.grid--setAreas;
	
	grid-column-gap: @colGap;
	grid-template-columns: repeat(4, 1fr);
	grid-template-areas: 
		"a a a a"
		"b b b c"
		"d d d d";
		
	@media @mediaQuery__md {
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas: 
		"a"
		"b"
		"c"
		"d";	
	}		
}
.grid--pageTemplateLinear {
	//color: red;
	
	//*
	grid-column-gap: @colGap;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	//*/
	
	//display: flex;
	//flex-direction: column;
	//min-height: 100%;
}
.grid--pageTemplateWithFixedSubmenu {
	.grid--setAreas;
	
	//color: red;
	
	grid-column-gap: @colGap;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto;
	grid-template-areas: 
		"a b b b"
		"a c c c"
		". d d d";	
		
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {
		grid-template-areas: 
			"a b b b"
			"c c c c"
			"d d d d";	
	}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
}
.grid--pageTemplateProduct {
	.grid--setAreas;
	
	//color: red !important;
	
	grid-column-gap: @colGap;
	grid-template-columns: repeat(4, 1fr);
	grid-template-areas: 
		"a a a a"
		"b b b c"
		"d d d ."
		"e e e e";
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {
		grid-template-columns: repeat(5, 1fr);
		grid-template-areas: 
			"a a a a a"
			"b b b b c"
			"d d d d ."
			"e e e e e";
	}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {
		grid-template-areas: 
			"a a a a"
			"b b b b"
			"d d d d"
			"c c c c"
			"e e e e";	
	}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
}

.grid--LuMainHeader {
	display: flex;
	
	@media @mediaQuery__md {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: @colGap;
		grid-template-columns: 1fr 3fr;
	}		
	@media @mediaQuery__sm {}
}
.grid--LuPageHeader {
	grid-column-gap: @colGap;
	grid-template-columns: repeat(5, 1fr);
	
	@media @mediaQuery__dt {
		grid-template-columns: 1fr 1fr;
	}		
	@media @mediaQuery__md {
		grid-template-columns: 1fr 2fr;
	}		
	@media @mediaQuery__sm {
		grid-template-columns: 5fr;
	}
}
.grid--LuProductTeasers {
	grid-column-gap: @colGap;
	grid-template-columns: repeat(4, 1fr);
	grid-row-gap: @colGap*2;
	
	@media @mediaQuery__lg {
		grid-template-columns: repeat(5, 1fr);				
	}		
	@media @mediaQuery__md {
		grid-template-columns: repeat(3, 1fr);
	}		
	@media @mediaQuery__sm {
		grid-template-columns: repeat(2, 1fr);
	}
}

.grid--LuCartRow-products {
	grid-template-columns: 2rem 1fr 6rem 5rem 6rem 2rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4rem 2.5rem 4.5rem 1.5rem;			
	}		
}
.grid--LuCartRow-productsSum {
	grid-template-columns: 2rem 1fr 5rem 6rem 2rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4.5rem 1.5rem;	
	}		
}
.grid--LuCartRow-finishOrder {
	grid-template-columns: 8rem 1fr;
}
.grid--LuCartRow-shipping {
	grid-template-columns: 2rem 1fr 12rem 6rem;
	
	@media @mediaQuery__md {
		grid-template-columns: 1.5rem 1fr 12rem 4.5rem; 
	}
}
.grid--LuCartRow-payment {
	grid-template-columns: 2rem 1fr 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 1.5rem 1fr; 
	}
}
.grid--LuCartRow-billingAndDeliveryAddresses {
	grid-template-columns: 10rem 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 6rem 1fr; 
	}
}
.grid--LuCartRow-finishingCustomer {
	grid-template-columns: repeat(3, 1fr);
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr;
	}
}
.grid--LuCartRow-finishingProducts {
	grid-template-columns: 2rem 1fr 6rem 5rem 6rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4rem 2.5rem 4.5rem;
	}			
}
.grid--LuCartRow-finishingProductsSum {
	grid-template-columns: 2rem 1fr 3rem 9.5rem 4rem 7rem;				
}
.grid--LuCartRow-finishingShipping {
	grid-template-columns: 2rem 1fr 6rem 5rem 6rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4rem 2.5rem 4.5rem;
	}		
}
.grid--LuCartRow-finishingSum {
	grid-template-columns: 2rem 1fr 5rem 6rem; 
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4.5rem;
	}
}
.grid--LuCartRow-finishingAccept {
	grid-template-columns: 2rem 30rem 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 1.5rem 1.5fr;
	}
}
.grid--LuCartRow-thankYou {
	grid-template-columns: 2fr 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr;	
	}	
}
.grid--LubokCart-shoppingInfos {
	grid-template-columns: repeat(3, 1fr);
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr;	
	}	
}

.height {}
.height--pageHeader {
	min-height: 9rem;
}
.height--navRow {
	min-height: @height[navRow];
}

.hide {}
.hide--sm {
	@media @mediaQuery__xs { display: none; }
	@media @mediaQuery__sm { display: none; }
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
}

.hSpace {}
.hSpace--productTeasers {
	padding-left: 1rem;
	padding-right: 1rem;
}
.hSpace--page {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	
	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
}
.hSpace--gapEnlargeRight {
	padding-right: 1rem;
	
	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {
		padding-right: 0rem;
	}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}	
}
.hSpace--gapEnlargeLeft {
	padding-left: 1rem;
	
	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {
		padding-left: 0rem;
	}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}	
}

.hyphenate {
	hyphens: auto;		
}

.icon {
	position: relative;
	overflow: hidden;
	text-indent: -100em;
	width: 1em;
	
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.icon--lense {
	//display: none;
	//background-image: url(./assets/imgs/icon--lense.svg);
	width: 1.25rem; //height: 1em;
	margin-right: 0.1rem;
	outline: 1px solid red;
	background-color: currentColor;
	mask: url(./../assets/imgs/icon--lense.svg) no-repeat 50% 50%;
	mask-size: contain;
}
.icon--triangle {
	outline: 1px solid red;
	width: 0.65rem; //height: 1em;
	margin-right: 0.3rem;
	background-color: currentColor;
	mask: url(./../assets/imgs/icon--triangle.svg) no-repeat 50% 50%;
	mask-size: contain;
}

.inline {
	display: inline;
}
.inlineBlock {
	display: inline-block;
}

.link {
	color: inherit;
	text-decoration: none;
}
.link--underlined {
	border-bottom: 1px dotted;
	
	&:hover { border-bottom-style: solid; }
}
.link--withArrow {
	position: relative;
	padding-right: 2em; // prevent intersection with arrow
	
	// arrow on the right side
	&::after {
		//.font--bold;
		
		position: absolute;
		top: 0; right: 0;
		content: "→";
	}
}
.link--color {
	color: @swatches[awardColor]; 
}

.maxWidth {
}
.maxWidth--headline {
	//.outline( red );
	max-width: 40rem;
}
.maxWidth--text {
	//.outline( red );
	max-width: 40em;
}
.maxWidth--page {
	max-width: 75rem;
}

.minHeight {} 
.minHeight--100 {
	min-height: 100vh;
}

.relative {
	position: relative;
}

.rotate {
	transition: transform 0.25s ease;		
}
.rotate--180 {
	transform: rotate(180deg);
}

.shadow {}
.shadow--productImage {
	filter: drop-shadow( 0.5rem 0.5rem 0.75rem fade( black, 80 ) );
	// 2019-09-29  	translate3d oder translateZ führt in chrome zu 
	// 				deutlich schlechterer render performance beim scrollen
	//				im shop view. eigentlich sollte es ja andersrum sein ...
	//transform: translateZ(1px);
	//transform: translate3d(0, 0, 0); 
	z-index: 1; // fixes some render issues (blurry text)
	
	@media @mediaQuery__md {
		// hide the shadows for better mobile scrolling performance
		// die war nämlich ziemlich hakelig auf den shop seiten
		filter: none; 		
	}
}
.shadow--cartButton {
	//filter: drop-shadow( 0.2rem 0.2rem 0.25rem fade( black, 80 ) );
	box-shadow: 0rem 0.2rem 0.5rem 0.25em fade( black, 25 );
	z-index: 1; // fixes some render issues (blurry text)
}	
.shadow--none {
	filter: drop-shadow( 0rem 0rem 0rem transparent );
}	

.spinner {}
.spinner--sizeDefault {
	width: 1rem;
	height: 1rem;
}
.spinner--sizeMedium {
	height: 2rem;
	width: 2rem;
}

.sticky {
	position: sticky !important;
	top: 0;
}

.todo {
	.outline( red );
}

.vSpace {}
.vSpace--shopProducts {
	padding-top: 1.5rem;
	padding-bottom: 1rem;
}
.vSpace--shopProductsTop {
	padding-top: 1.5rem;
}
.vSpace--productTeasers {
	padding-top: 2.5rem;
	padding-bottom: 1rem;
}
.vSpace--page {
	padding-top: 2rem;
	padding-bottom: 2rem;
	
	@media @mediaQuery__md {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}	
.vSpace--pageBottom {
	padding-bottom: 1rem;
}	
.vSpace--pageHeaderTitle {
	padding-top: 0.2rem;			
	padding-bottom: 0.4rem;			
}
.vSpace--pageContentTitle {
	padding-top: 0.3rem;			
	padding-bottom: 1em;			
}
.vSpace--pageContentText {
	//padding-top: 0.4rem;			
	padding-bottom: 2em;
}
.vSpace--pageContentGallery {
	//padding-top: 0.4rem;			
	padding-bottom: 2em;
}
.vSpace--pagination {
	padding-top: 3em;
	padding-bottom: 3em;
}
.vSpace--productGallery {
	padding-top: 0.55rem;
	padding-bottom: 1rem;
}
.vSpace--productBiblios {
	padding-top: 0.25rem;
	padding-bottom: 1rem;
}
.vSpace--productText {
	padding-top: 0.5rem;
	padding-bottom: 6rem;
}
.vSpace--productRelated {
	padding-top: 0.5rem;
	padding-bottom: 6rem;
}
.vSpace--productTextInfos {
	padding-top: 0.35em;
	padding-bottom: 1em;	
}
.vSpace--LuGallery {
	@top    : 0.6rem;
	@bottom : 1rem;
	
	padding-top    : @top;
	padding-bottom : @bottom; 
	
	.outlineVSpace( @top, @bottom, red );	
}
.vSpace--LuText {
	@top    : 0.5rem;
	@bottom : 1rem;
	
	padding-top    : @top;
	padding-bottom : @bottom; 
	
	.outlineVSpace( @top, @bottom, red );	
}
.vSpace--LuCartRow {
	@top    : 0.3rem;
	@bottom : 0.3rem;
	
	padding-top    : @top;
	padding-bottom : @bottom; 
	
	.outlineVSpace( @top, @bottom, red );	
}
.vSpace--LuHeadline {
	@top    : 0.4rem;
	@bottom : 1rem;
	
	padding-top    : @top;
	padding-bottom : @bottom; 
	
	.outlineVSpace( @top, @bottom, brown );	
}
.vSpace--LuNewsletterWidget,
.vSpace--LuEventsWidget {
	@top    : 0.35rem;
	@bottom : 0.35rem;
	
	padding-top    : @top;
	padding-bottom : @bottom; 
	
	.outlineVSpace( @top, @bottom, blue );	
}
.vSpace--navRow {
	@top    : 0.3rem;
	@bottom : 0.3rem;
	
	padding-top    : @top;
	padding-bottom : @bottom; 
	
	.outlineVSpace( @top, @bottom, green );
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
}
.vSpace--navFooterRow {
	@top    : 0.5rem;
	@bottom : 0rem;
	
	padding-top    : @top;
	padding-bottom : @bottom; 
	
	.outlineVSpace( @top, @bottom, green );	
}
.vSpace--noBottom {
	padding-bottom: 0;
}

.view {
	position: relative;
	min-height: 100vh;
	
	@media @mediaQuery__dt {
		padding-left: 2vw;
		padding-right: 2vw;
	}
}
	
.width {}
.width--100 { 
	width: 100%; 
}



// tags below to let them include classes above
body {
	.font; 
	.font--sans; 
	.font--sizeDefault;
}
strong, b {
	.font--bold;
}
button {
	cursor: pointer;
}
h1, h2, h3, h4, h5 {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	margin: 0;
}
a {
	cursor: pointer;
	color: blue;
	text-decoration: none;
}
pre {
	@color: black;
	
	position: relative;
	padding: 0.5em;
	width: 100%;
	min-height: 2em;
	
	margin-bottom: 0.5em;
	&:first-of-type { margin-top: 1em; }
	&:last-of-type { margin-bottom: 1em; }
	
	//color: fade(@color, 65);
	tab-size: 4;
	white-space: pre;
	
	outline: 1px solid fade(@color, 25);
	background-color: fade(@color, 5);
	overflow: auto;		
	//display: none;
	
	// label
	&[name]:after {
		position: absolute;
		top: 0; right: 0;
		background-color: fade(@color, 15);
		font-size: 13px;
		line-height: 1em;
		font-family: sans-serif;
		color: fade(@color, 75);
		padding: 0.25em 0.5em;
		content: attr(name);
		font-family: inherit;
	}		
	
	&:first-child {
		margin-top: 0em;
	}
}
hr {
	border: none;
	border-top: 1px solid;
	margin: 0.5em 0;
	opacity: 0.25;
}
